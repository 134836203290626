import request from '@/utils/request'

export function getTags(data) {
  return request({
    url: '/tags',
    method: 'get',
    params: data
  })
}
export function getCategories(data) {
  return request({
    url: '/categories',
    method: 'get',
    params: data
  })
}
export function getPublications(data, status = 0) {
  let includes = status > 0 ? '&includes=status': '';
  return request({
    url: `/publications?orderBy=id&order=asc${includes}`,
    method: 'get',
    params: data
  })
}
