import request from '@/utils/request'

let shortLink = process.env.VUE_APP_BASE_API_SHORT
export function login(data) {
  return request({
    url: shortLink + '/login',
    method: 'post',
    data
  })
}

export function logout() {
  return request({
    url: '/',
    method: 'post'
  })
}

export function getUserData(data) {
  return request({
    url: shortLink + '/me',
    method: 'get',
    params: data
  })
}
// Bookmark
export function addToBookmark(data) {
  return request({
    url: 'user/articles',
    method: 'post',
    data
  })
}
export function addToBookmarkImage(data) {
  return request({
    url: 'user/images',
    method: 'post',
    data
  })
}
export function removeFromBookmarkImage(id) {
  return request({
    url: 'user/images/' + id,
    method: 'delete',
  })
}
export function addToBookmarkVideo(data) {
  return request({
    url: 'user/videos',
    method: 'post',
    data
  })
}
export function removeFromBookmarkVideo(id) {
  return request({
    url: 'user/videos/' + id,
    method: 'delete',
  })
}
export function removeFromBookmark(id) {
  return request({
    url: 'user/articles/' + id,
    method: 'delete',
  })
}
export function bookmarkList(data) {
  return request({
    url: 'user/articles',
    method: 'get',
    params: data
  })
}
export function bookmarkListImages(data) {
  return request({
    url: 'user/images',
    method: 'get',
    params: data
  })
}
export function bookmarkListVideos(data) {
  return request({
    url: 'user/videos',
    method: 'get',
    params: data
  })
}
// Puplication Articles
export function shareToPuplicationArticles(data) {
  return request({
    url: 'user/publication/articles',
    method: 'post',
    data
  })
}
export function shareToPuplicationImages(data) {
  return request({
    url: 'user/publication/images',
    method: 'post',
    data
  })
}
export function shareToPuplicationVideos(data) {
  return request({
    url: 'user/publication/videos',
    method: 'post',
    data
  })
}
export function publicationArticlesList(data) {
  return request({
    url: 'user/publication/articles',
    method: 'get',
    params: data
  })
}
export function publicationVideosList(data) {
  return request({
    url: 'user/publication/videos',
    method: 'get',
    params: data
  })
}
export function publicationImagesList(data) {
  return request({
    url: 'user/publication/images',
    method: 'get',
    params: data
  })
}

// Basket
export function addToBasket(data) {
  return request({
    url: 'user/basket/articles',
    method: 'post',
    data
  })
}
export function removeFromBasket(id) {
  return request({
    url: 'user/basket/articles/' + id,
    method: 'delete',
  })
}
export function basketList(data) {
  return request({
    url: 'user/basket/articles',
    method: 'get',
    params: data
  })
}


export function updateMyProfile(data) {
  return request({
    url: 'users/me',
    method: 'post',
    data
  })
}

export function getMyTeam(data) {
  return request({
    url:'users/myTeam',
    method: 'get',
    params: data
  })
}
export function getEditors(data) {
  return request({
    url:'users/editors',
    method: 'get',
    params: data
  })
}
export function getUsers(data) {
  return request({
    url:'/users',
    method: 'get',
    params: data
  })
}
export function getUserDetails(id) {
  return request({
    url: `/users/${id}`,
    method: 'get',
  })
}
export function addNewUser(data) {
  return request({
    url: `/users`,
    method: 'post',
    data
  })
}
export function updateUser(data, id) {
  return request({
    url: `/users/${id}`,
    method: 'put',
    data
  })
}
export function deleteUser(id) {
  return request({
    url: `/users/${id}`,
    method: 'delete',
  })
}

// Feedback
export function addFeedback(data) {
  return request({
    url: `/user/feedbacks`,
    method: 'post',
    data
  })
}
export function getFeedbacks(data) {
  return request({
    url:'/user/feedbacks',
    method: 'get',
    params: data
  })
}

