import Vue from 'vue'
import axios from 'axios'
import store from '../store/index'
Vue.mixin({
  store: store,
  data() {
    return {
      userWithPermissions: [],
      downloadVideoIsBuffering: false
    }
  },
  methods: {
    isInUserPermissions(value) {
      if (value && value instanceof Array && value.length > 0) {
        const roles = store.getters && store.getters.roles
        const permissionRoles = value

        const hasPermission = roles.some(role => {
          return permissionRoles.includes(role)
        })
        return hasPermission
      } else {
        return false
      }
    },
    async getVideoDataByJWPlayerId(JWPlayerId) {
      let response = await axios.get(`https://cdn.jwplayer.com/v2/media/${JWPlayerId}`);
      let url;
      if (response && response.status === 200 && response.data && response.data.playlist && response.data.playlist[0] && response.data.playlist[0].sources) {
        let sources = response.data.playlist[0].sources;
        sources.forEach(function(source) {
          if (source.type === 'video/mp4') {
              url = source.file;
          }
        });
      }

      return url;
    },
    async downloadMp4Video(jwplayerId, fileName, directLink = '') {
      this.downloadVideoIsBuffering = true;
      let fileUrl;
      if (directLink === '') {
        fileUrl = await this.getVideoDataByJWPlayerId(jwplayerId)
      } else {
        fileUrl = directLink;
      }

      axios.get(fileUrl, { responseType: 'blob' })
          .then(response => {
            const blob = new Blob([response.data], { type: 'video/mp4' })
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = fileName
            this.downloadVideoIsBuffering = false;
            link.click()
            URL.revokeObjectURL(link.href)
          }).catch((error) => {

        })
    }
  },
})
