import Vue from "vue";
import VueRouter from "vue-router";
import MainLayout from "../layouts/MainLayout";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/auth/LoginView.vue"),
  },
  {
    path: "/",
    redirect: "/articles",
    component: MainLayout,
    children: [
      {
        path: "/articles",
        name: "articles",
        component: () => import("../views/articles/ArticlesListView.vue"),
        meta: {
          roles: ["user", "admin"],
        },
      },
      {
        path: "/videos",
        name: "videos",
        component: () => import("../views/videos/VideosListView.vue"),
        meta: {
          roles: ["user", "admin"],
        },
      },
      {
        path: "/images",
        name: "images",
        component: () => import("../views/photos/PhotosListView.vue"),
        meta: {
          roles: ["user", "admin"],
        },
      },
      {
        path: "/my-videos",
        name: "my-videos",
        component: () => import("../views/videos/MyVideosListView.vue"),
        meta: {
          roles: ["user", "admin"],
        },
      },
      {
        path: "/trending",
        name: "trending",
        component: () => import("../views/trending/TrendingListView.vue"),
        meta: {
          roles: ["user", "admin"],
        },
      },
      {
        path: "/users",
        name: "users",
        component: () => import("../views/users/UsersListView.vue"),
        meta: {
          roles: ["admin"],
        },
      },
      // {
      //   path: "/feedbacks",
      //   name: "feedbacks",
      //   component: () => import("../views/feedbacks/FeedbacksListView.vue"),
      //   meta: {
      //     roles: ["user", "admin"],
      //   },
      // },
      {
        path: "/profile",
        name: "profileView",
        component: () => import("../views/profile/ProfileView.vue"),
        meta: {
          roles: ["user", "admin"],
        },
      },
      {
        path: "/bookmark",
        name: "bookmark",
        component: () => import("../views/bookmark/BookmarkListView.vue"),
        meta: {
          roles: ["user", "admin"],
        },
      },
      {
        path: "/publicationArticles",
        name: "publicationArticles",
        component: () => import("../views/publicationArticles/PublicationArticlesListView.vue"),
        meta: {
          roles: ["user", "admin"],
        },
      },
      {
        path: "/mybasket",
        name: "mybasket",
        component: () => import("../views/basket/BasketListView.vue"),
        meta: {
          roles: ["user", "admin"],
        },
      },
      {
        path: "/publications",
        name: "publications",
        component: () => import("../views/publications/PublicationsListView.vue"),
        meta: {
          roles: ["admin"],
        },
      },
      {
        path: "/notAuthorized",
        name: "notAuthorized",
        component: () => import("../views/notAuthorized/NotAuthorizedView.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: () => ({ y: 0 }),
});

export default router;
