
import router from './index'
import { getToken } from "@/utils/auth"; // get token from cookie
import store from "../store/index";

const whiteList = ["/login", "/auth-redirect"]; // no redirect whitelist

router.beforeEach(async (to, from, next) => {
  // set page title
  // document.title = getPageTitle(to.meta&&to.meta.title)

  // determine whether the user has logged in
  const hasToken = getToken();

  if (hasToken) {
    store.dispatch("user/handleUserData");
    if (to.path === "/login") {
      // if is logged in, redirect to the home page
      next({ path: "/" });
    } else {

      let hasRoles = store.getters.roles && store.getters.roles.length > 0;

      if (hasRoles) {
        let allRoles = store.getters.roles
        if (to.meta && to.meta.roles) {
          if (allRoles.some((role) => to.meta.roles.includes(role))) {
            return next();
          }
        }
        if (to.path != "/notAuthorized") {
          return next({ path: "/notAuthorized" });
        }
        return next();
      }
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next();
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`);
    }
  }
});


// router.beforeEach(async (to, from, next) => {
//   // set page title
//   // document.title = getPageTitle(to.meta.title)

//   // determine whether the user has logged in

//   if (hasToken) {
   
//     if (to.path === "/login") {
//       // if is logged in, redirect to the home page
//       next({ path: "/" });
//     } else {
//       // if(to.meta&&to.meta.authorized === true){
//       //   return next();
//       // }
//       // return next();
//       let hasRoles = store.getters.roles && store.getters.roles.length > 0;

//       if (hasRoles) {
//         let allRoles = store.getters.roles
//         if (to.meta && to.meta.roles) {
//           if (allRoles.some((role) => to.meta.roles.includes(role))) {
//             return next();
//           }
//         }
//         if (to.path != "/notAuthorized") {
//           return next({ path: "/notAuthorized" });
//         }
//         return next();
//       }
//     }
//   } else {
//     /* has no token*/
//     if (whiteList.indexOf(to.path) !== -1) {
//       // in the free login whitelist, go directly
//       next();
//     } else {
//       // other pages that do not have permission to access are redirected to the login page.
//       next(`/login?redirect=${to.path}`);
//     }
//   }
// });