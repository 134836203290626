import Vue from 'vue'





Vue.filter('arFormat', function (value) {
    if (!value) return ''

    var d = new Date(value);
    var local = d.getTime();
    var offset = d.getTimezoneOffset() * (60 * 1000);
    var utc = new Date(local + offset);
    var riyadh = new Date(utc.getTime() + (3 * 60 * 60 * 1000));
    
    // let date = new Date(value);
    let options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    };
    let formattedDate = riyadh.toLocaleDateString('ar-EG-u-nu-latn', options);
    let formattedTime = riyadh.toLocaleTimeString('ar-SA-u-nu-latn', {hour: '2-digit', minute:'2-digit'});

    return formattedTime + ' بتوقيت الرياض ' + formattedDate
  });
Vue.filter('arFormatDate', function (value) {
    if (!value) return ''
    var d = new Date(value);
    var local = d.getTime();
    var offset = d.getTimezoneOffset() * (60 * 1000);
    var utc = new Date(local + offset);
    var riyadh = new Date(utc.getTime() + (3 * 60 * 60 * 1000));
    
    // let date = new Date(value);
    let options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    };
    let formattedDate = riyadh.toLocaleDateString('ar-EG-u-nu-latn', options);

    return formattedDate
  });
Vue.filter('arFormatTime', function (value) {
    if (!value) return ''
    var d = new Date(value);
    var local = d.getTime();
    var offset = d.getTimezoneOffset() * (60 * 1000);
    var utc = new Date(local + offset);
    var riyadh = new Date(utc.getTime() + (3 * 60 * 60 * 1000));
    
    // let date = new Date(value);
    let options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    };
    let formattedTime = riyadh.toLocaleTimeString('ar-SA-u-nu-latn', {hour: '2-digit', minute:'2-digit'});

    return formattedTime + ' بتوقيت الرياض '
  });