import Vue from 'vue'
import Cookies from 'js-cookie'

import App from './App.vue'
import router from './router'
import './router/routerRoles'
import store from './store'
import AppLoader from '@/components/global/AppLoader'
import AppEmptyState from '@/components/global/AppEmptyState'

import vSelect from 'vue-select'

import infiniteScroll from 'vue-infinite-scroll'
Vue.use(infiniteScroll)

import Buefy from 'buefy'
import 'buefy/dist/buefy.css'

import 'vue-select/dist/vue-select.css';

// Global Mixin
import './mixin/global'
// Global Filter
import './filter/global'

// Bootstrap Grid
import './assets/css/bootstrap-grid.min.css'

// Main Style
import './styles/main.scss'


// Global Components
Vue.component('AppLoader', AppLoader);
Vue.component('AppEmptyState', AppEmptyState);


Vue.component('v-select', vSelect)

Vue.use(Buefy)

Vue.config.productionTip = false


export const app = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

