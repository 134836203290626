import { login, getUserData, updateMyProfile } from '@/api/user'
import { getToken, setToken, removeToken, getRoles, setRoles, removeRoles,
  getUserInfo, setUserInfo, removeUserInfo, setUserAccessibility, getUserAccessibility, removeUserAccessibility} from '@/utils/auth'
import router from '@/router'
// import jwt_decode from "jwt-decode";

const state = {
  token: getToken(),
  userData: getUserInfo()&&JSON.parse(getUserInfo()),
  roles: getRoles()&&JSON.parse(getRoles()),
  userAccessibility: getUserAccessibility()? JSON.parse(getUserAccessibility()) : {publications: []},
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_USERDATA: (state, data) => {
    state.userData = data
  },
  SET_ROLES: (state, data) => {
    state.roles = data
  },
  SET_USER_ACCESSIBILITY: (state, data) => {
    state.userAccessibility = data
  },
  
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { email, password } = userInfo
    return new Promise((resolve, reject) => {
      login({ email: email.trim(), password: password }).then(response => {
        if(response.success){
          const { token, admin } = response.data
          commit('SET_TOKEN', token)
          setToken(token)

          let roles = ['user']
          if(admin){
            roles.push('admin')
          }
          commit('SET_ROLES', roles)
          setRoles(JSON.stringify(roles))
        }
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  // Update User Data

  async updateUserData({ commit, state }, {publications, videosPublications, imagesPublications, isArticles, isVideos, isImages}){
    let settings = state.userAccessibility
    const formData = new FormData();
    if(isArticles){
      settings.publications = publications || []
    }
    if(isVideos){
      settings.videosPublications = videosPublications || []
    }
    if(isImages){
      settings.imagesPublications = imagesPublications || []
    }
    formData.append("settings", JSON.stringify(settings));
    commit('SET_USER_ACCESSIBILITY', settings)
    const response = await updateMyProfile(formData).then(res => {
        if (res.success) {
            if(res.data.settings){
                setUserAccessibility(JSON.stringify(res.data.settings))
            }
        }
    })

  },

  
  login({ commit }, userInfo) {
    const { email, password } = userInfo
    return new Promise((resolve, reject) => {
      login({ email: email.trim(), password: password }).then(response => {
        if(response.success){
          const { token, admin } = response.data
          commit('SET_TOKEN', token)
          setToken(token)

          let roles = ['user']
          if(admin){
            roles.push('admin')
          }
          commit('SET_ROLES', roles)
          setRoles(JSON.stringify(roles))
        }
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  handleUserData({ commit }) {
    getUserData().then(response => {
        if(response.success){
          const { admin } = response.data
          let roles = ['user']
          if(admin){
            roles.push('admin')
          }
          commit('SET_USERDATA', response.data)
          commit('SET_ROLES', roles)
          setRoles(JSON.stringify(roles))
          setUserInfo(JSON.stringify(response.data))
          if(response.data.settings){
            setUserAccessibility(JSON.stringify(response.data.settings))
          }
        }
      })
  },

  // handleUserData({ commit }){
  //   let token = getToken()
  //   // let user = jwt_decode(token);
  //   // commit('SET_ROLES', user.roles)
  //   // commit('SET_USERDATA', {name: user.given_name, jobTitle: user.jobTitle, id: user.id, email: user.email})
  // },

  // user logout
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {

      commit('SET_TOKEN', '')
      // commit('SET_ROLES', [])
      removeToken()
      removeRoles()
      removeUserInfo()
      setUserAccessibility()
      resolve()
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      // commit('SET_ROLES', [])
      removeToken()
      removeRoles()
      removeUserInfo()
      setUserAccessibility()
      resolve()
    })
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
