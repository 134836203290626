<template>
    <div>
        <aside class="app-sidebar">
            <div class="wrap--sidebar">
                <div class="upper--sidebar">
                    <a href="/" class="logo">
                        <img src="@/assets/images/srmg.png" alt="SRMG">
                    </a>
                </div>
                <div class="menu-items">
                    <div class="menu-item">
                        <!-- <router-link
                            to="/articles"
                            active-class="is-active"
                            class="menu-link"
                        >
                            <span class="icon"><i class="fa fa-th"></i></span>
                            News Articles
                        </router-link> -->
                        <div class="menu-link">
                            <span class="icon"><i class="fa fa-th"></i></span>
                            News Articles
                        </div>
                        <ul class="subMenu">

                            <!-- <app-loader :small="true" v-if="isLoadingPublications"/>

                            <li class="subMenuItem" v-else> -->


                            <!-- <div v-for="(value, key , index) in publicationsList" :key="index">
                                    <b-collapse
                                        animation="slide"
                                        :open="isOpenDropdown == index + 1"
                                        @open="isOpenDropdown = index + 1"
                                        aria-id="contentIdForA11y1">
                                        <template #trigger="props">
                                            <div class="subMenuLabel mb-0" role="button" :aria-controls="`contentIdFor+${index}`" :aria-expanded="props.open" >
                                                {{key}}
                                                <span class="icon">
                                                    <i :class=" props.open? 'fa fa-angle-down' : 'fa fa-angle-right'"></i>
                                                </span>
                                            </div>
                                        </template>
                                        <div class="block mt-3">
                                            <b-checkbox v-model="checkboxPublications"
                                                v-for="item in value"
                                                :key="item.id"
                                                :class="checkboxPublications.includes(item.id)? 'checkbox-selected' : ''"
                                                @input="handleSelectCheckbox(item.id)"
                                                :native-value="item.id">
                                                {{item.name}}
                                            </b-checkbox>
                                        </div>
                                    </b-collapse>
                                </div> -->

                            <!-- <b-collapse
                                    animation="slide"
                                    :open="isOpenDropdown == 2"
                                    @open="isOpenDropdown = 2"
                                    aria-id="contentIdForA11y2">
                                    <template #trigger="props">
                                        <div class="subMenuLabel mb-0" role="button" aria-controls="contentIdForA11y2" :aria-expanded="props.open" >
                                            English
                                            <span class="icon">
                                                <i :class=" props.open? 'fa fa-angle-down' : 'fa fa-angle-right'"></i>
                                            </span>
                                        </div>
                                    </template>
                                    <div class="block mt-3">
                                        <b-checkbox v-model="checkboxPublications"
                                            v-for="item in publicationsList"
                                            :key="item.id"
                                            :class="checkboxPublications.includes(item.id)? 'checkbox-selected' : ''"
                                            @input="handleSelectCheckbox(item.id)"
                                            :native-value="item.id">
                                            {{item.name}}
                                        </b-checkbox>
                                    </div>
                                </b-collapse>
                                <b-collapse
                                    animation="slide"
                                    :open="isOpenDropdown == 3"
                                    @open="isOpenDropdown = 3"
                                    aria-id="contentIdForA11y3">
                                    <template #trigger="props">
                                        <div class="subMenuLabel mb-0" role="button" aria-controls="contentIdForA11y3" :aria-expanded="props.open" >
                                            Others
                                            <span class="icon">
                                                <i :class=" props.open? 'fa fa-angle-down' : 'fa fa-angle-right'"></i>
                                            </span>
                                        </div>
                                    </template>
                                    <div class="block mt-3">
                                        <b-checkbox v-model="checkboxPublications"
                                            v-for="item in publicationsList"
                                            :key="item.id"
                                            :class="checkboxPublications.includes(item.id)? 'checkbox-selected' : ''"
                                            @input="handleSelectCheckbox(item.id)"
                                            :native-value="item.id">
                                            {{item.name}}
                                        </b-checkbox>
                                    </div>
                                </b-collapse> -->
                            <!-- </li> -->
                            <li class="subMenuItem">
                                <router-link to="/articles" active-class="is-active" class="subMenuLabel">
                                    Article
                                </router-link>
                            </li>
                            <li class="subMenuItem">
                                <router-link to="/videos" active-class="is-active" class="subMenuLabel">
                                    Video
                                </router-link>
                            </li>
                        </ul>
                    </div>
                    <div class="menu-item">
                        <div class="menu-link">
                            <span class="icon"><i class="fa fa-video"></i></span>
                            Media Library
                        </div>
                        <ul class="subMenu">
                            <li class="subMenuItem">
                                <router-link to="/images" active-class="is-active" class="subMenuLabel">
                                    Images
                                </router-link>
                            </li>
                            <li class="subMenuItem">
                                <router-link to="/my-videos" active-class="is-active" class="subMenuLabel">
                                    Videos
                                </router-link>
                            </li>
                        </ul>
                    </div>
                    <div class="menu-item">
                        <router-link to="/trending" active-class="is-active" class="menu-link">
                            <span class="icon"><i class="fas fa-list"></i></span>
                            Trending
                        </router-link>
                    </div>
                    <div class="menu-item" v-if="userData && userData.admin">
                        <router-link to="/publications" active-class="is-active" class="menu-link">
                            <span class="icon"><i class="fa fa-flag"></i></span>
                            System Status
                        </router-link>
                    </div>
                    <div class="menu-item" v-if="userData && userData.admin">
                        <router-link to="/users" active-class="is-active" class="menu-link">
                            <span class="icon"><i class="fa fa-users"></i></span>
                            Users
                        </router-link>
                    </div>
                    <!-- <div class="menu-item">
                        <router-link to="/feedbacks" active-class="is-active" class="menu-link">
                            <span class="icon">
                                <i class="fas fa-comments"></i>
                            </span>
                            Feedbacks
                        </router-link>
                    </div> -->

                    <!--
                    <div class="menu-item">
                        <router-link
                            to="/trending"
                            active-class="is-active"
                            class="menu-link"
                        >
                            <span class="icon"
                                ><i class="fas fa-list"></i
                            ></span>
                            Trending
                        </router-link>
                    </div> -->


                    <div class="menu-item lower-menu-items">
                        <div @click="$router.push('/profile')" class="menu-text">
                            <span class="icon"><i class="fas fa-cog"></i></span>
                            <span> My Settings</span>
                        </div>
                        <!--
                            <div class="menu-text" @click="$router.push('/mybasket')">
                                <span class="icon"><i class="fas fa-shopping-basket"></i></span>
                                <span> My Basket <span class="num">({{ totalBasket }})</span></span>
                            </div>
                         -->
                        <div @click="$router.push('/bookmark')" class="menu-text">
                            <span class="icon"><i class="fas fa-bookmark"></i></span>
                            <span> Favourite 
                                <!-- <span class="num"
                                    v-if="totalBookmark !== null">({{ totalBookmark }})</span> -->
                            </span>
                        </div>
                        <div class="menu-text" @click="$router.push('/publicationArticles')">
                            <span class="icon"><i class="fas fa-share-alt"></i></span>
                            <span> Publication Data 
                                <!-- <span class="num"
                                    v-if="totalPublicationArticles !== null">({{ totalPublicationArticles }})</span> -->
                                </span>
                        </div>
                        <!-- <div class="menu-text" @click="isModalFeedback = true" v-if="userData && !userData.admin">
                            <span class="icon"><i class="fas fa-comment-alt"></i></span>
                            <span>Feedback</span>
                        </div> -->
                        <!-- <ul class="nav">
                            <li class="nav--item">
                                <a href="#!" class="nav--link">Saved (3)</a>
                                <a href="#!" class="nav--link"
                                    >Shared With Me (0)</a
                                >
                            </li>
                        </ul> -->
                    </div>

                    <!--

 -->

                </div>
            </div>
        </aside>
        <div class="overlay-sidebar" @click="closeSidebar"></div>

        <b-modal v-model="isModalFeedback" :width="640" scroll="keep" @close="handleCloseFeedback()">
            <div class="card">
                <div class="card-header">
                    <p class="card-header-title">
                        Add Feedback
                    </p>
                </div>
                <div class="card-content">
                    <div class="modal-body">
                        <form @submit.prevent="addFeedback">
                            <b-field label="Feedback" class="mb-4">
                                <b-input type="textarea" rows="4" v-model="feedbackMessage" />
                            </b-field>
                            <div class="card-action buttons mt-5 justify-content-center">
                                <b-button class="is-dark" @click="isModalFeedback = false">Cancel</b-button>
                                <b-button native-type="submit" class="is-primary"
                                    :disabled="isLoadingFeedbackBtn || !feedbackMessage"
                                    :loading="isLoadingFeedbackBtn">Send</b-button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </b-modal>

    </div>
</template>

<script>
import { getPublications } from '@/api/global'
import {
    bookmarkList, basketList, publicationArticlesList, addFeedback, publicationVideosList,
    publicationImagesList
} from '@/api/user'
import { mapState } from "vuex";

export default {
    data() {
        return {
            checkboxPublications: [],
            publicationsList: [],
            isOpenDropdown: null,
            isLoadingPublications: false,
            totalBookmark: null,
            totalBasket: null,
            totalPublicationArticles: null,
            isModalFeedback: false,
            feedbackMessage: '',
            isLoadingFeedbackBtn: false
        };
    },
    created() {
        // this.getPublicationsList();
        this.handleQueryFromUrl();
        // this.bookmarkTotal();
        // this.basketTotal();
        // this.getAllPublicationArticles();

    },
    mounted() {
        // this.$root.$on("updateBasketTotal", (data) => {
        //     this.totalBasket = data
        // });
        this.$root.$on("updateBookmark", (data) => {
            if (data === true) {
                this.bookmarkTotal();
                return
            }
            this.totalBookmark = data
        });
    },
    computed: {
        ...mapState({
            userData: (state) => state.user.userData,
            userAccessibility: (state) => state.user.userAccessibility,
        }),
    },
    watch: {
        // '$route'(to, from){
        //     if((to.query&&to.query.openArticle) || (from.query&&from.query.openArticle)) {
        //         return
        //     }
        //     
        // }
        'userAccessibility.publications'() {
            this.handleQueryFromUrl();
        }
    },
    methods: {
        closeSidebar() {
            document.body.classList.remove("openSidebar");
        },
        // Handle Query From URL
        handleQueryFromUrl() {
            // let query = Object.assign({}, this.$route.query);
            this.checkboxPublications = this.userAccessibility.publications || []
            // if(query.publication){
            //     if(query.publication.length > 1){
            //         query.publication.map(item => {
            //             this.checkboxPublications.push(parseInt(item))
            //         })
            //     } else {
            //         if(query.publication&&query.publication.length){
            //             this.checkboxPublications.push(parseInt(query.publication))
            //         }
            //     }
            // }
        },
        // Get Publications
        async getPublicationsList() {
            this.isLoadingPublications = true
            const response = await getPublications({ groupBy: 'lang' })
            if (response) {
                this.publicationsList = response.data
            }
            this.isLoadingPublications = false
        },
        // Handle Change Select Publications
        handleSelectCheckbox(value) {

            if (value === 'all' && this.checkboxPublications.includes('all')) {
                this.checkboxPublications = this.publicationsList.map(item => {
                    return item.id
                })
                return
            }
            if (value == 'all' && !this.checkboxPublications.includes('all')) {
                this.checkboxPublications = []
            }

            // let query = Object.assign({}, this.$route.query);
            // query.publication = this.checkboxPublications
            // if(this.$route.name == 'articles') {
            //     this.$router.replace({ query });
            //     // window.location.reload()
            // } else {
            //     this.$router.push({ path: '/articles', query });
            // }
            this.$store.dispatch("user/updateUserData", { publications: this.checkboxPublications })

        },

        // Get Bookmark Total 
        async bookmarkTotal() {
            const response = await bookmarkList({ limit: 0, page: 1, pagination: true })
            if (response && response.data) {
                this.totalBookmark = response.data.total
            }
        },
        // Get Basket Total 
        async basketTotal() {
            const response = await basketList({ limit: 0, page: 1, pagination: true })
            if (response && response.data) {
                this.totalBasket = response.data.total
            }
        },
        // Get Publication Articles Total 
        async getAllPublicationArticles() {
            const response = await publicationArticlesList({ limit: 0, page: 1, pagination: true })
            const responseVideos = await publicationVideosList({ limit: 0, page: 1, pagination: true })
            const responseImages = await publicationImagesList({ limit: 0, page: 1, pagination: true })
            if (response && response.data) {
                this.totalPublicationArticles = response.data.total + responseVideos.data.total + responseImages.data.total
            }
        },
        handleCloseFeedback() {
            this.feedbackMessage = ''
        },
        async addFeedback() {

            this.isLoadingFeedbackBtn = true
            const response = await addFeedback({ message: this.feedbackMessage }).then(res => {
                if (res.success) {
                    this.isModalFeedback = false
                    this.feedbackMessage = ''
                }
                this.isLoadingFeedbackBtn = false
            }).catch(error => {
                this.isLoadingFeedbackBtn = false
            })
        }
    },
};
</script>
