<template>
    <div class="app-header">
        <div class="left-content">
            <button class="button btn--menu" @click="toggleOpenSidebar">
                <i class="fas fa-bars icon--open"></i>
            </button>
            <!-- <div class="buttons">
                <router-link to="/" class="button is-primary">SRMG Sources</router-link>
                <router-link to="/trending" class="button is-info">Trending Now</router-link>
                <router-link to="/" class="button is-warning">Breaking</router-link>
            </div> -->
        </div>
        <div class="item--right d-flex align-items-center">
            <!-- <div class="mr-3">
                <b-dropdown
                    position="is-bottom-left"
                    append-to-body
                    aria-role="menu"
                    trap-focus
                >
                    <template #trigger>
                        <a class="navbar-item button" role="button">
                            <i class="fas fa-search"></i>
                        </a>
                    </template>
                    <b-dropdown-item
                        aria-role="menu-item"
                        :focusable="false"
                        custom
                        paddingless
                    >
                        <form action="">
                            <div class="modal-card" style="width: 400px">
                                <section class="modal-card-body">
                                    <b-field>
                                        <b-input
                                            placeholder="Search..."
                                            expanded
                                            type="search"
                                        >
                                        </b-input>
                                        <p class="control">
                                            <b-button
                                                type="is-primary"
                                                label="Search"
                                            />
                                        </p>
                                    </b-field>
                                </section>
                            </div>
                        </form>
                    </b-dropdown-item>
                </b-dropdown>
            </div> -->

            <!-- <b-dropdown
                position="is-bottom-left"
                append-to-body
                class="ml-2"
                aria-role="menu"
                trap-focus
            >
                <template #trigger>
                    <a class="navbar-item button" role="button">
                            <i class="fas fa-list"></i>
                        </a>
                    <b-tooltip position="is-bottom" multilined>
                        
                        <template v-slot:content>
                            <div class="content">
                                <strong class="has-text-white">Change article view</strong><br />
                                 You can choose between title, magazine, cards, and article views.
                            </div>
                        </template>
                    </b-tooltip>

                </template>
                <b-dropdown-item
                    aria-role="menu-item"
                    :focusable="false"
                    custom
                    paddingless
                >
                    <section class="p-3">
                        <b-field>
                            <b-radio
                                v-model="listType"
                                @input="changeViewType"
                                native-value="card-item-title"
                            >
                                Title List View
                            </b-radio>
                        </b-field>
                        <b-field>
                            <b-radio
                                v-model="listType"
                                @input="changeViewType"
                                native-value="card-item-magazine"
                            >
                                Magazine View
                            </b-radio>
                        </b-field>
                        <b-field>
                            <b-radio
                                v-model="listType"
                                @input="changeViewType"
                                native-value="card-item-normal"
                            >
                                Cards View
                            </b-radio>
                        </b-field>
                    </section>
                </b-dropdown-item>
            </b-dropdown> -->

            <!-- <b-button class="button ml-2 btn--redo">
                <i class="fas fa-redo-alt"></i>
                <span class="circle"></span>
            </b-button> -->

            <b-dropdown
                position="is-bottom-left"
                append-to-body
                v-if="userData"
                aria-role="menu"
                trap-focus
            >
                <template #trigger>
                    <a class="navbar-item user-login-content" role="button">
                        <span class="user-avatar" v-if="userData.avatar">
                            <img :src="userData.avatar">
                        </span>
                        <span class="user-avatar" v-else>
                            {{firstChar}}
                        </span>
                        <div class="user-name">
                            Hi, {{userData.name}}
                        </div>
                    </a>
                </template>
                <b-dropdown-item
                    aria-role="menu-item"
                    :focusable="false"
                    custom
                    paddingless
                >
                    <b-dropdown-item aria-role="listitem" @click="$router.push('/profile')">Profile</b-dropdown-item>
                    <b-dropdown-item aria-role="listitem" @click="logout()">Logout</b-dropdown-item>
                </b-dropdown-item>
            </b-dropdown>

        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    data() {
        return {
            searchForm: false,
        };
    },
    computed: {
        ...mapState({
            userData: (state) => state.user.userData,
        }),

        firstChar(){
            return this.userData&&this.userData.name.charAt(0)
        }
    },
    methods: {
        toggleOpenSidebar(){
            document.body.classList.toggle("openSidebar");
        },
        async logout() {
            await this.$store.dispatch("user/logout");
            this.$router.push(`/login`);
        },
    },
};
</script>
