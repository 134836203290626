import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'
const RolesKey = 'User-Roles'
const UserKey = 'User-Data'
const UserAccessibility = 'User-Accessibility'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
export function getRoles() {
  return Cookies.get(RolesKey)
}

export function setRoles(Roles) {
  return Cookies.set(RolesKey, Roles)
}

export function removeRoles() {
  return Cookies.remove(RolesKey)
}
export function getUserInfo() {
  return localStorage.getItem(UserKey)
}

export function setUserInfo(data) {
  return localStorage.setItem(UserKey, data)
}
export function setUserAccessibility(data) {
  return localStorage.setItem(UserAccessibility, data)
}
export function getUserAccessibility(data) {
  return localStorage.getItem(UserAccessibility)
}
export function removeUserAccessibility(data) {
  return localStorage.removeItem(UserAccessibility)
}

export function removeUserInfo() {
  return localStorage.removeItem(UserKey)
}
