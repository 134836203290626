<template>
  <div class="LoaderCard" :class="{'small': small}">
    <b-loading v-model="isLoading" :is-full-page="false"></b-loading>
  </div>
</template>

<script>
  export default {
    data(){
      return {
        isLoading: true
      }
    },
    props: {
      small: {
        default: false
      }
    }
  }
</script>

<style scoped lang="scss">
    .LoaderCard {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: 300px;
        position: relative;
        margin-top: 20px;
        &.small{
          min-height: 150px;
        }
    }
</style>
