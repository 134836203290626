<template>
    <div>
        <!-- App Header -->
        <AppNavbar />

        <!-- App Sidebar -->
        <AppSidebar />

        <div class="wrapper">
            <!-- App View Pages -->
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import AppNavbar from './components/AppNavbar.vue'
import AppSidebar from './components/AppSidebar.vue'
import { mapState } from "vuex";
export default {
    data(){
        return {

        }
    },
    computed: {
        ...mapState({
            userData: (state) => state.user.userData,
            userAccessibility: (state) => state.user.userAccessibility,
        }),
    },
    components: {
        AppNavbar,
        AppSidebar,
    },
    created(){
        // this.$store.dispatch("user/handleUserData")
        if(this.userAccessibility){
            const {listView, theme, fontSize} = this.userAccessibility
            document.body.classList.add('theme-' + theme)
            if(listView){
                this.changeViewType('card-item-' + listView)
            }
            if(fontSize){
                this.handleFontSize(fontSize)
            }
        }
    },
     methods: {
        changeViewType(value) {
            document.body.classList.remove(
                "card-item-title",
                "card-item-magazine",
                "card-item-normal"
            );
            document.body.classList.add(value);
        },
        handleFontSize(size){
            let fontSize = 16
            switch (size) {
                case 1:
                fontSize = 14
                    break;
                case 2:
                fontSize = 16
                    break;
                case 3:
                fontSize = 18
                    break;
                case 4:
                fontSize = 20
                    break;
                case 5:
                fontSize = 22
                    break;
            }
            document.documentElement.style.setProperty('--text-size', fontSize + 'px');
        }
     }
}
</script>