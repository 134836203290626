import axios from 'axios'
import store from '@/store'
import router from '@/router/index'
import { getToken } from '@/utils/auth'
import { app } from "../main";

import { ToastProgrammatic as Toast } from 'buefy'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  // timeout: 60000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent

    if (store.getters.token) {
      const token = store.getters.token ? `Bearer ${store.getters.token}` : "";      
      config.headers.Authorization = token
    }
    return config
  },

  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {

    const res = response.data
    if(res.code === 201 || res.code === 202) {
      // if(res.message == 'Item has been stored successfully'){
      //   handleModalMessage('Added to favourite')
      // } else {
      // }
      handleModalMessage(res.message)
      return res
    }
    if(res.success) {
      return res
    }

    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 20000) {
      return Promise.reject(new Error(res.message || 'Error'))
    } else {

      return res
    }
  },
  error => {
    
    if(error.response&&error.response.status === 401){
      // if(error.response&&error.response.data.message){
      //   Toast.open({
      //       duration: 5000,
      //       message: `${error.response.data.message}`,
      //       position: 'is-bottom',
      //       type: 'is-danger'
      //   })
      // }    
      handleLogout()
    }

    if(error.response&&error.response.status === 400 && error.response.data.message){
      Toast.open({
          duration: 5000,
          message: `${error.response.data.message}`,
          position: 'is-bottom',
          type: 'is-danger'
      })
    }    
    return Promise.reject(error)
  }
)

const handleModalMessage = (message) => {
  if(message){
    Toast.open({
      duration: 5000,
      message: `${message}`,
      position: 'is-bottom',
      type: 'is-success'
    })
  }
}

const handleLogout = async () => {
  await store.dispatch("user/logout")
  if(app.$route.name != 'login'){
    router.push("/login")
  }
}

export default service
